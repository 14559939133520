@use 'sass:map';
@use '@angular/material' as mat;

@import "../src/assets/css/variable.scss";


/*.mat-expansion-panel {
  background: #272d47 !important;
    color: #d5dae2;
}

.mat-expansion-indicator::after {
    color: #d5dae2;
}
.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: $menuBg1;
}
.mat-toolbar.mat-primary {
    background: $white-bg;
    color: #503031;
}
.mat-expansion-panel-content{
    .mat-expansion-panel-body {
      padding: 0 10px 16px;
  }
  }
  ::-webkit-scrollbar {
    width: 10px;
    height:10px;
    overflow-y: scroll;
    background: #F9D697;
    box-shadow: inset 0 0 4px #F9D697;
}*/

$custom-palette: map-merge(mat.$grey-palette, (50:#fff));
$app-palette: mat.define-palette($custom-palette, 50, 100, 700, 900);
$brand-palette: mat.define-palette(mat.$amber-palette, 700);
$secondary-palette: mat.define-palette(mat.$grey-palette, 50, 100, 700, 900);
$base-theme: mat.define-light-theme((color: (primary: $app-palette,
        accent: $brand-palette,
      ),
      typography: mat.define-typography-config("'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif"),
      density: 0,
    ));
$base-background-customized: map-merge(mat.$light-theme-background-palette, ("background":#fff));
//@debug $base-theme;
$app-theme: (
  color:(primary:map-get($base-theme, primary),
    accent:map-get($base-theme, accent),
    warn:map-get($base-theme, warn),
    is-dark: map-get($base-theme, is-dark),
    foreground: map-get($base-theme, foreground),
    background: $base-background-customized ),
  typography: mat.define-typography-config("'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif"),
);
@include mat.core($app-theme);
//@debug $app-theme;
@include mat.all-component-themes($app-theme);

$app-foreground: map-get($base-theme, foreground);


$button-theme: mat.define-light-theme((color: (primary: $brand-palette,
        accent: $brand-palette ),
      typography: mat.define-typography-config("'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif"),
      density: 0,
    ));

@include mat.button-color($button-theme);

//$light-background-customized: map-merge(mat.$light-theme-background-palette,("card":#f3f4f6,"dialog": #f3f4f6,"app-bar":#f3f4f6,"background":#f3f4f6) );
//$light-background-customized: map-merge(mat.$light-theme-background-palette,("card":#fff,"dialog": #fff,"app-bar":#fff,"background":#fff) );
$light-background-customized: map-merge(mat.$light-theme-background-palette, ("card":#fff, "dialog": #fff, "app-bar":#fff, "background":#fff));

$sidebar-theme: (
  color:(primary:map-get($base-theme, primary),
    accent:map-get($base-theme, accent),
    warn:map-get($base-theme, warn),
    is-dark: map-get($base-theme, is-dark),
    foreground: map-get($base-theme, foreground),
    background: $light-background-customized ),
  typography: mat.define-typography-config("'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif"),

);


.secondary-theme {
  @include mat.all-component-colors($sidebar-theme);
}



span:focus {
  outline: none;
  border: 0;
}

input::placeholder {
  font-size: 0.8em;
}

.mat-sort-header-button {
  text-transform: capitalize;
}

.mat-checkbox-indeterminate,
.mat-checkbox-checked {
  .mat-checkbox-background {
    background-color: $primary !important;
  }
}

.fs-flex-column {
  @extend .fs-flex;
  flex-direction: column;
}

.fs-flex {
  display: flex;
}

.fs-primary-text {
  color: mat.get-color-from-palette($app-foreground, text);
}

.fs-secondary-text {
  color: mat.get-color-from-palette($app-foreground, secondary-text);
}

.fs-hint-text {
  color: mat.get-color-from-palette($app-foreground, hint-text);
}

.fs-margin-0 {
  margin: 0 !important;
}

.fs-margin-top-1 {
  margin-top: 1em !important;
}

.fs-margin-bottom-1 {
  margin-bottom: 1em !important;
}

.fs-margin-top-2 {
  margin-top: 2em;
}

.fs-margin-bottom-2 {
  margin-bottom: 2em;
}

.fs-margin-1 {
  margin: 1rem;
}

.fs-margin-2 {
  margin: 2rem;
}

.fs-margin-1-0 {
  margin: 1rem 0rem;
}

.fs-margin-2-0 {
  margin: 2rem 0rem;
}


.fs-margin-0-1 {
  margin: 0rem 1rem;
}

.fs-margin-0-2 {
  margin: 0rem 2rem;
}

.fs-padding-0 {
  margin: 0 !important;
}

.fs-padding-1 {
  padding: 1rem;
}

.fs-padding-2 {
  padding: 2rem;
}

.fs-padding-1-0 {
  padding: 1rem 0rem;
}

.fs-padding-2-0 {
  padding: 2rem 0rem;
}


.fs-padding-0-1 {
  padding: 0rem 1rem;
}

.fs-padding-0-2 {
  padding: 0rem 2rem;
}

.fs-padding-1-1-0 {
  padding: 1rem 1rem 0rem;
}

.fs-section-block {
  margin-bottom: 1rem;
  @extend .fs-padding-1-1-0;
}

.fs-font-weight-500 {
  font-weight: 500 !important;
}

.fs-font-weight-600 {
  font-weight: 600 !important;
}

.fs-font-weight-300 {
  font-weight: 300 !important;
}

.fs-font-weight-700 {
  font-weight: bolder !important;
}

button {
  &:has(.mat-icon) {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

img {
  object-fit: contain;
}

.trend-container {

  display: inline-flex;
  margin-left: 5px;
  gap: 3px;
  font-size: .9em;
  flex-direction: row-reverse;

  .mat-icon{
    width: 1em;
    height: 1em;
    font-size: 1em;
  }
  .trend-negative {
    color: red;
    flex-direction: row-reverse;
    display: flex;

    .postive-icon {
      display: none;
    }
  }

  .trend-positive {
    color: green;
    flex-direction: row-reverse;
    display: flex;

    .negative-icon {
      display: none;
    }
  }

  .trend-hide {
    display: none;
  }
}

/**
.mat-row:hover {
    background-color: #F8DBA9;
  }

::-webkit-scrollbar-thumb {
  background: $primary1;
  border-radius: 10px;
}



.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color:$primary;
    color:$black;
    font-size: 0.9em;
    font: 500 14px/32px Roboto, "Helvetica Neue", sans-serif;
    text-transform: uppercase;
    border: 1px solid $primary;
}
.btn-primary:hover {
    color: $primary;
    background-color: $black;
    border-color: $black;
}
**/

@keyframes flowsmart-placeholder-anim {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}


.flowsmart-placeholder {

  background: lightgrey;
  animation: flowsmart-placeholder-anim 2s infinite;
}

.placeholder-template {
  font-style: normal;
  text-align: center;
  width: 80%;
  height: 1em;
  margin: 0px auto;
}

.display-flex {
  display: flex;
  align-items: center
}

::-webkit-scrollbar {
  width: 2px;
  overflow-y: auto;
  //background: #1E1E1E;
  //box-shadow: inset 0 0 2px #707070;
}

::-webkit-scrollbar-thumb {
  background: inherit;
  border-radius: 10px;
}

.opacity-50 {
  opacity: 50%;
}

.opacity-25 {
  opacity: 25%;
}

.note-block {
  margin-top: 20px;
  background: none;
  font-style: italic;
  font-size: 14px;

  .mat-icon {
    height: 16px;
    width: 16px;
    font-size: 14px;
    font-style: italic;
  }
}

/** override grid styling **/


.e-grid .e-frozenhdrcont .e-headercontent>.e-table,
.e-grid .e-frozenhdrcont .e-frozenheader>.e-table,
.e-grid .e-frozenhdrcont .e-movableheader>.e-table,
.e-grid .e-frozenhdrcont .e-headercontent .e-virtualtable>.e-table {
  border-bottom-color: $primary;
}

.e-grid .e-frozencontent {
  border-bottom-color: #e0e0e0;
}

.e-grid .e-frozenheader>.e-table,
.e-grid .e-frozencontent>.e-table,
.e-grid .e-frozencontent .e-virtualtable>.e-table,
.e-grid .e-frozenheader .e-virtualtable>.e-table {
  border-right-color: $primary;
}

.e-grid .e-frozenheader.e-frozenborderdisabled>.e-table,
.e-grid .e-frozencontent.e-frozenborderdisabled>.e-table,
.e-grid .e-frozencontent.e-frozenborderdisabled .e-virtualtable>.e-table,
.e-grid .e-frozenheader.e-frozenborderdisabled .e-virtualtable>.e-table {
  border-right-color: #fff;
}

.e-grid .e-frozenheader.e-frozen-right-header>.e-table,
.e-grid .e-frozencontent.e-frozen-right-content>.e-table,
.e-grid .e-rowcell .e-frozen-default-cursor,
.e-grid .e-gridheader .e-headercell .e-frozen-default-cursor,
.e-grid .e-gridheader .e-filterbarcell .e-frozen-default-cursor {
  border-left-color: $primary;
}

.e-grid .e-frozenheader.e-frozen-right-header.e-frozenborderdisabled>.e-table,
.e-grid .e-frozencontent.e-frozen-right-content.e-frozenborderdisabled>.e-table {
  border-left-color: #fff;
}

.e-grid.e-rtl .e-frozenheader>.e-table,
.e-grid.e-rtl .e-frozencontent>.e-table,
.e-grid.e-rtl .e-frozenheader .e-virtualtable>.e-table,
.e-grid.e-rtl .e-frozencontent .e-virtualtable>.e-table {
  border-left-color: $primary;
}

.e-grid.e-rtl .e-frozenheader.e-frozen-right-header>.e-table,
.e-grid.e-rtl .e-frozencontent.e-frozen-right-content>.e-table {
  border-right-color: $primary;
}

.header-background {
  background-color: whitesmoke;
}

@import 'ngx-toastr/toastr';

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-diagrams/styles/material.css';
@import "../node_modules/@syncfusion/ej2-angular-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-pivotview/styles/material.css';